import { useEffect, useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { gql, useMutation } from '@apollo/client'
import { OAuth2Context } from '../../context/oauth2-context'
import { CAMPAIGN_FIELDS } from '../../graphql/campaign-fragments'
import Loader from '../../layouts/components/Loader'

const CONNECT_CAMPAIGN_TO_PLATFORM = gql`
    ${CAMPAIGN_FIELDS}
    mutation ConnectCampaignToPlatform($oAuthPlatformInput: OAuthPlatformInput) {
        connectCampaignToPlatform(oAuthPlatformInput: $oAuthPlatformInput) {
            ...CampaignFields
            platforms {
                id
            }
        }
    }
`

const CONNECT_CAMPAIGN_TO_PUBLISHER = gql`
    mutation ConnectCampaignToPublisher($oAuthPublisherInput: OAuthPublisherInput) {
        connectCampaignToPublisher(oAuthPublisherInput: $oAuthPublisherInput) {
            id
        }
    }
`

const OAuthCallback = (props) => {
    const context = useContext(OAuth2Context)
    const navigate = useNavigate()
    const [code, setCode] = useState(null)

    const [connectCampaignToPlatform] = useMutation(CONNECT_CAMPAIGN_TO_PLATFORM, {
        onError({ graphQLErrors }) {
            context.removeState()
            navigate('/campaigns/platforms/' + context.campaignId, {
                state: {
                    notification: {
                        status: 'error',
                        message: graphQLErrors[0]?.message
                    }
                }
            })
        },
        onCompleted: () => {
            context.removeState()
            navigate('/campaigns/platforms/' + context.campaignId, {
                state: {
                    notification: {
                        status: 'success',
                        message: 'The platform has been connected successfully!'
                    }
                }
            })
        },
        variables: {
            oAuthPlatformInput: {
                id: context.platform,
                code: code,
                campaignId: context.campaignId,
                redirectUri: `${document.location.origin}/callback`
            }
        }
    })

    const [connectCampaignToPublisher] = useMutation(CONNECT_CAMPAIGN_TO_PUBLISHER, {
        onError({ graphQLErrors }) {
            context.removeState()
            navigate('/campaigns/' + context.campaignId + '/media-buying/', {
                state: {
                    notification: {
                        status: 'error',
                        message: graphQLErrors[0]?.message
                    }
                }
            })
        },
        onCompleted: () => {
            context.removeState()
            navigate('/campaigns/' + context.campaignId + '/media-buying/', {
                state: {
                    notification: {
                        status: 'success',
                        message: 'The publisher has been connected successfully!'
                    }
                }
            })
        },
        variables: {
            oAuthPublisherInput: {
                id: context.platform,
                code: code,
                campaignId: context.campaignId,
                redirectUri: `${document.location.origin}/callback`
            }
        }
    })

    useEffect(() => {
        if (window.location.pathname !== '/callback') return
        const queryParams = new URLSearchParams(window.location.search)
        const state = queryParams.get('state')
        const error = queryParams.get('error')
        const code = queryParams.get('code')
        if (state !== context.state || !code || error) {
            const url = context.page === 'platform'
                ? '/campaigns/platforms/' + context.campaignId
                : '/campaigns/' + context.campaignId + '/media-buying/'
            context.removeState()
            navigate(url, {
                state: {
                    notification: {
                        status: 'error',
                        message: error || 'Could not connect ' + context.page
                    }
                }
            })
        }
        setCode(code)
    }, [context, navigate])

    useEffect(() => {
        if (code) {
            if (context.page === 'platform') {
                connectCampaignToPlatform()
            } else {
                connectCampaignToPublisher()
            }
        }
    }, [code, connectCampaignToPlatform])

    return <Loader />
}

export default OAuthCallback
