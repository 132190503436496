import React, { useContext, useState, useEffect } from 'react'
import { gql, useQuery } from '@apollo/client'
import { Col } from 'react-bootstrap'
import Chart from 'react-apexcharts'
import Card from '../common/Card'
import { AuthContext } from '../../context/auth-context'
import Loader from '../../layouts/components/Loader'

const GET_INSIGHTS = gql`
    query Publishers($publishersWhere: PublisherWhere, $adCampaignsWhere: AdCampaignWhere) {
        publishers(where: $publishersWhere) {
            name
            adCampaigns(where: $adCampaignsWhere) {
                landingPagesConnection {
                    edges {
                        conversionRate
                        node {
                            id
                        }
                    }
                }
            }
        }
    }`

const GET_LANDING_PAGES = gql`
    query LandingPages($where: LandingPageWhere) {
        landingPages(where: $where) {
            id
            title
            campaign {
                name
            }
        }
    }`

const LiveCampaignPerformanceChart = ({ name }) => {
    const { user } = useContext(AuthContext)
    const [conversionRates, setConversionRates] = useState({})
    const [labels, setLabels] = useState([])

    const landingPages = useQuery(GET_LANDING_PAGES)
    const insights = useQuery(GET_INSIGHTS, {
        variables: {
            adCampaignsWhere: {}
        }
    })

    const calculateTop5ConversionRates = (data) => {
        const keyValuePairs = Object.entries(data)
        const creatives = landingPages.data.landingPages
        keyValuePairs.sort((a, b) => b[1] - a[1])
        const top5Pairs = keyValuePairs.slice(0, 5)
        const top5Object = Object.fromEntries(top5Pairs)

        const labels = Object.keys(top5Object).map((item) => {
            const creative = creatives.find((creative) => creative.id === item)
            return creative.campaign.name + ' - ' + creative.title
        })

        setConversionRates(top5Object)
        setLabels(labels)
    }

    useEffect(() => {
        if (insights.data?.publishers.length > 0 && landingPages.data?.landingPages.length > 0) {
            const data = insights.data.publishers.find((publisher) => publisher.name === 'Meta').adCampaigns
            const current = {}
            const conversionPerAds = {}

            const conversionPerAdsData = data.reduce((acc, campaign) => {
                campaign.landingPagesConnection.edges.forEach((edge) => {
                    if (!acc[0][edge.node.id]) {
                        acc[0][edge.node.id] = 0 // For accumulating conversion rates
                    }
                    if (!acc[1][edge.node.id]) {
                        acc[1][edge.node.id] = 0 // For counting occurrences
                    }

                    // Accumulate the conversion rate and count
                    acc[0][edge.node.id] += edge.conversionRate
                    acc[1][edge.node.id] += 1

                    // Combine with current conversion rate and count
                    if (current[edge.node.id] !== undefined) {
                        acc[0][edge.node.id] += current[edge.node.id]
                        acc[1][edge.node.id] += 1
                        delete current[edge.node.id]
                    }
                })
                return acc
            }, [{}, {}])

            Object.keys(conversionPerAdsData[0]).forEach((key) => {
                conversionPerAds[key] = conversionPerAdsData[0][key] / conversionPerAdsData[1][key]
            })

            calculateTop5ConversionRates(conversionPerAds)
        }
    }, [insights.data, landingPages.data])

    const options = {
        chart: {
            type: 'bar',
            height: 350,
            toolbar: {
                show: false
            }
        },
        colors: ['#427EEB'],
        plotOptions: {
            bar: {
                borderRadius: 4,
                horizontal: true,
            }
        },
        dataLabels: {
            enabled: false
        },
        xaxis: {
            categories: process.env.REACT_APP_DEMO_USER_ID.includes(user.id)
                ? ['Landing Page 1', 'Landing Page 2', 'Landing Page 3', 'Landing Page 4', 'Landing Page 5']
                : labels
        },
        grid: {
            show: false
        },
    }

    const series = [{
        name: 'Conversion Rate (%)',
        data: process.env.REACT_APP_DEMO_USER_ID.includes(user.id)
            ? [75, 85, 78, 72, 84]
            : Object.values(conversionRates).map((item) => item ? item.toFixed(1) : 0)
    }]

    return (
        <Col md={12} xl={5}>
            { insights.loading || landingPages.loading ? (
                <Loader fullScreen={false} />
            ) : (
                <Card data-aos="fade-up" data-aos-delay="1000">
                    <div className="flex-wrap card-header d-flex justify-content-between">
                        <div className="header-title">
                            <h4 className="card-title">{name}</h4>
                        </div>
                    </div>
                    <Card.Body>
                        <Chart className="d-activity" options={options} series={series} type="bar" />
                    </Card.Body>
                </Card>
            )}
        </Col>
    )
}

export default LiveCampaignPerformanceChart