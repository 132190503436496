import React,{ memo, Fragment } from 'react'
import Card from '../common/Card'
import Loader from '../../layouts/components/Loader'
import ProgressBar from '../common/ProgressBar'
import CountUp from 'react-countup'

const icon = (
    <svg width="44" height="44" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M21.9964 8.37513H17.7618C15.7911 8.37859 14.1947 9.93514 14.1911 11.8566C14.1884 13.7823 15.7867 15.3458 17.7618 15.3484H22V15.6543C22 19.0136 19.9636 21 16.5173 21H7.48356C4.03644 21 2 19.0136 2 15.6543V8.33786C2 4.97862 4.03644 3 7.48356 3H16.5138C19.96 3 21.9964 4.97862 21.9964 8.33786V8.37513ZM6.73956 8.36733H12.3796H12.3831H12.3902C12.8124 8.36559 13.1538 8.03019 13.152 7.61765C13.1502 7.20598 12.8053 6.87318 12.3831 6.87491H6.73956C6.32 6.87664 5.97956 7.20858 5.97778 7.61852C5.976 8.03019 6.31733 8.36559 6.73956 8.36733Z" fill="currentColor"></path>
        <path opacity="0.4" d="M16.0374 12.2966C16.2465 13.2478 17.0805 13.917 18.0326 13.8996H21.2825C21.6787 13.8996 22 13.5715 22 13.166V10.6344C21.9991 10.2297 21.6787 9.90077 21.2825 9.8999H17.9561C16.8731 9.90338 15.9983 10.8024 16 11.9102C16 12.0398 16.0128 12.1695 16.0374 12.2966Z" fill="currentColor"></path>
        <circle cx="18" cy="11.8999" r="1" fill="currentColor"></circle>
    </svg>
)

const PurchaseWidget = memo(({ data, loading }) =>{
    const start = 0
    const duration = 3
    const rate = 40
    const colour = 'bg-soft-primary'
    const progress = 'primary'

    return (
        <Fragment>
            { loading ? (
                <Loader fullScreen={false} />
            ) : (
                <Card>
                    <Card.Body className="d-flex align-items-center">
                        <div className={`p-4 bg-soft-primary rounded-pill ${colour}`} style={{marginRight: '20px'}}>
                            {icon}
                        </div>

                        <div className="progress-detail w-100">
                            <div className="d-flex justify-content-between">
                                <p className="mb-3">Purchase</p>
                            </div>
                            <h4 className="counter">
                                <CountUp start={start} end={data.purchase} duration={duration}/>
                                <div className="mt-1">
                                    $
                                    <CountUp start={start} end={data.purchaseValue} duration={duration}/>
                                </div>
                            </h4>
                            <div className="w-100">
                                <ProgressBar
                                    softcolors={progress}
                                    color={progress}
                                    className="shadow-none w-100"
                                    value={rate}
                                    minvalue={0}
                                    maxvalue={100}
                                    style={{height: '8px'}}
                                />
                            </div>
                        </div>
                    </Card.Body>
                </Card>
            )}
        </Fragment>
    )
})

export default PurchaseWidget